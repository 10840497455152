import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";

import breakDownAllNodes from "utils/breakDownAllNodes";
import Wrapper from "containers/Wrapper";
import Product from "containers/Product";

export const query = graphql`
  query ProductsQuery($langKey: String!) {
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          imageFileName
          anchor
          sections {
            anchor
          }
          copyright
          social {
            linkedin
          }
          privacyText
          termsText
          
          titleTemplate
          keywords
          description
          
          bgColor
          textColor
          iconFileName
          title
          desc
          textButton
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const ProductsPage = ({ pathContext, data }) => {
  const { allMarkdownRemark: { nodes } } = data;
  const { productsNode, productNodes } = breakDownAllNodes(nodes);

  return (
    <Wrapper
      pathContext={pathContext}
      nodes={nodes}
      node={productsNode}
    >
       <Product
        pathContext={pathContext}
        productNodes={productNodes}
      />
    </Wrapper>
  );
};

ProductsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object,
};

ProductsPage.defaultProps = {
  pathContext: {
    langKey: "de",
    defaultLang: "de",
    langTextMap: {},
  },
};

export default ProductsPage;
