import React from "react";
import PropTypes from "prop-types";
import { Col, Container, ResponsiveEmbed, Row } from "react-bootstrap";
import Image from "components/Image";

import "./ProductDetails.scss";
import Form from "containers/Form";

const ProductDetails = ({ frontmatter, frontmatterForm }) => {
  if (!frontmatter) return null;

  const {
    bgColor, textColor, header: rootHeader, subheader, content: rootContent, items, serviceForm, contentForm
  } = frontmatter;

  const style = {
    backgroundColor: bgColor || null,
    color: textColor || null,
  };

  return (
    <div className='py-5' style={style}>
      <Container>
        <div className='text-center pb-5'>
          <h1 className='display-4 text-uppercase mb-5'
              dangerouslySetInnerHTML={{ __html: rootHeader }}/>
          <div style={{ borderColor: textColor }}
               className='product-details__subheader'>
            <h3 className='text-uppercase'
                dangerouslySetInnerHTML={{ __html: subheader }}/>
            <p className='mb-1 lead'
               dangerouslySetInnerHTML={{ __html: rootContent }}/>
          </div>
        </div>

        <div className='pb-5'>
          {items.map(({ imageFileName, header, content }, i) => (
            <Row
              key={header}
              className={i % 2 === 1 ? "flex-md-row-reverse" : ""}>
              <Col md={6} className='px-0'>
                <ResponsiveEmbed aspectRatio="1by1">
                  <Image
                    className='position-absolute'
                    fileName={imageFileName}
                  />
                </ResponsiveEmbed>
              </Col>
              <Col md={6} className="px-3 py-4 p-lg-4 text-center my-auto">
                <h1
                  className='text-uppercase mb-4'
                  dangerouslySetInnerHTML={{ __html: header }}
                />
                <p
                  className='lead mb-0'
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </Col>
            </Row>
          ))}
        </div>

        {frontmatterForm && (
          <Row>
            <Col lg={8}>
              <Form
                frontmatter={frontmatterForm}
                service={serviceForm}
              />
            </Col>
            <Col lg={4}>
              <h3 className='p-4 text-center'>{contentForm}</h3>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

ProductDetails.propTypes = {
  frontmatter: PropTypes.object,
  frontmatterForm: PropTypes.object,
};

ProductDetails.defaultProps = {
  frontmatter: null,
  frontmatterForm: null,
};

export default ProductDetails;
