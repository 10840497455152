import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { scroller } from "react-scroll";

import Icon from "components/Icon";

const NavItemProduct = ({ active, frontmatter, to }) => {
  if (!frontmatter) return null;
  // eslint-disable-next-line no-unused-vars
  const { bgColor, textColor, iconFileName, title, desc, textButton } = frontmatter;

  const classItem = active ? "bg-white" : "bg-white-transparent";
  const styleIcon = {
    color: bgColor || null,
    // filter: `drop-shadow(0 0 10px ${textColor})`,
  };
  const styleLink = {
    color: bgColor || null,
    // textShadow: `0 0 10px ${textColor}`,
  };

  return (
    <div
      className={`p-3 text-dark mx-auto h-100 d-flex flex-column ${classItem} rounded`}
      style={{ maxWidth: "300px" }}
    >
      <div className='d-flex mb-2'>
        <Icon className="icon" iconName={iconFileName} size="3x"
              style={styleIcon}/>
      </div>
      <h4>{title}</h4>
      <p>{desc}</p>
      <div className='d-flex mt-auto'>
        {textButton && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <a
            className='nav-link pl-0 pb-1'
            style={styleLink}
            onClick={() => {
              if (!active) navigate(`${to}/`);
              if (active) scroller.scrollTo("productDetails", {
                duration: 1500,
                delay: 0,
                offset: -53,
                smooth: "easeInOutQuart",
              });
            }}
          >
            {textButton}
          </a>
        )}
      </div>
    </div>
  );
};

NavItemProduct.propTypes = {
  frontmatter: PropTypes.object,
  to: PropTypes.string,
  active: PropTypes.bool,
};

NavItemProduct.defaultProps = {
  frontmatter: null,
  to: "",
  active: false,
};

export default NavItemProduct;
