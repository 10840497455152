import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import getBaseUrl from "utils/getBaseUrl";
import fileNameToSectionName from "utils/fileNameToSectionName";
import NavItemProduct from "components/NavItemProduct";
import ProductDetails from "components/ProductDetails";

const Product = ({ pathContext, productNodes, productNode, frontmatterForm }) => {
  const { defaultLang, langKey } = pathContext;
  const rootFileName = productNode ? productNode.fields.fileName : "";

  return (
    <>
      <div className="h-custom">
        <Container>
          <Row className='justify-content-center py-3'>
            {productNodes.map(({
              frontmatter, fields: { directoryName, fileName },
            }) => {
              const { anchor } = frontmatter;
              const to = `${getBaseUrl(defaultLang,
                langKey)}${directoryName}/${fileNameToSectionName(fileName)}`;
              const active = rootFileName === fileName;

              if (anchor === "") return null;
              return (
                <Col
                  key={fileName}
                  sm={6} lg={4} xl={3}
                  className='pt-3 pb-sm-3'
                >
                  <NavItemProduct
                    frontmatter={frontmatter}
                    to={to}
                    active={active}
                  />
                </Col>
              );
            })}
          </Row>

        </Container>
      </div>
      <div id='productDetails'/>
      {productNode && (
        <ProductDetails
          frontmatter={productNode.frontmatter}
          frontmatterForm={frontmatterForm}
        />
      )}
    </>
  );
};

Product.propTypes = {
  pathContext: PropTypes.object.isRequired,
  productNode: PropTypes.object,
  frontmatterForm: PropTypes.object,
  productNodes: PropTypes.array.isRequired,
};

Product.defaultProps = {
  productNode: null,
  frontmatterForm: null,
};

export default Product;
